/* You can add global styles to this file, and also import other style files */
@font-face {
      font-family: 'adt';
      src: url('/assets/font/adt.eot?37473213');
      src: url('/assets/font/adt.eot?37473213#iefix') format('embedded-opentype'),
           url('/assets/font/adt.woff?37473213') format('woff'),
           url('/assets/font/adt.ttf?37473213') format('truetype'),
           url('/assets/font/adt.svg?37473213#adt') format('svg');
      font-weight: normal;
      font-style: normal;
}

.adt-icon {
      font-family: "adt";
      font-style: normal;
      font-weight: normal;
      /* speak: none; */
     
      display: inline-block;
      text-decoration: inherit;
      //width: 1em;
      //margin-right: .2em;
      text-align: center;
      /* opacity: .8; */
     
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
     
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
     
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      // margin-left: .2em;
     
      /* You can be more comfortable with increased icons size */
      /* font-size: 120%; */
     
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
     
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
 }